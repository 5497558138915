import React, { createContext, useState, useContext,useEffect } from "react";
import numberFormat from "../../../Function/NumberFormat2float";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import CoinList from "./CoinList";
import TradingViewWidget from "./TradingViewWidget";
import { DashboardContext } from "../Dashboard";
import { Modal } from "antd";
import PaidIcon from "@mui/icons-material/Paid";
import { useTranslation } from "react-i18next";

export const TradeContext = createContext();



function Trade() {
  const { state } = useLocation();
  const { coin = "", name = "" } = state || {};
  const { userdata,ws } = useContext(DashboardContext);
  const [selectcurrency, setSelectCurrency] = useCookies(["selectcurrency"]);
  const [selectcurrencyname, setSelectCurrencyname] = useCookies(["selectcurrencyname"]);

  const [vsCurrency, setVsCurrency] = useState("usd");

  const [selectedSymbol, setSelectedSymbol] = useState(
    selectcurrency.selectcurrency || "BINANCE:BTCUSDT"
  );
  const [selectedSymbolname, setSelectedSymbolname] = useState(
    selectcurrencyname.selectcurrencyname || "BTCUSDT"
  );

  const [selecttabtrade, setSelecttabtrade] = useCookies(["selecttabtrade"]);
  const [tabValue, setTabValue] = useState(
    selecttabtrade.selecttabtrade? selecttabtrade.selecttabtrade.toString(): "1"
  );

  const [isModalCoin, setIsModalCoin] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if(state){
      setSelectedSymbol(coin);
      setSelectedSymbolname(name);
      setIsModalCoin(false);
      setSelectCurrency("selectcurrency", coin.toLowerCase(), {
        path: `/`,
      });
      setSelectCurrencyname("selectcurrencyname", name.toLowerCase(), {
        path: `/`,
      });
      setTabValue("1");
      setSelecttabtrade("selecttabtrade", "1", {
        path: `/`,
      });
    }
  }, []);
  return (
    <TradeContext.Provider
      value={{
        setIsModalCoin,
        setSelectCurrency,
        vsCurrency,
        setVsCurrency,
        setSelectedSymbol,
        userdata,
        setSelectCurrencyname,
        setSelectedSymbolname,
        tabValue,
        setTabValue,
        setSelecttabtrade
      }}
    >
      <div className="h-full trade_modal">
        <div className="text-xs flex items-center justify-between p-4 border rounded-lg shadow-sm bg-gray-800 mx-2 mb-2 lg:hidden">
          <div className="flex items-center">
            <div className="p-2 rounded-full bg-gray-700">
              {/* Assuming an icon component or image here */}
              <svg
                height="21"
                viewBox="0 0 21 21"
                width="21"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white stroke-2"
              >
                <g fill="none" fillRule="evenodd" transform="translate(3 4)">
                  <path
                    d="m.5 2.5h12c1.1045695 0 2 .8954305 2 2v6c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2zm1-2h9c.5522847 0 1 .44771525 1 1v1h-11v-1c0-.55228475.44771525-1 1-1z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle cx="11.5" cy="7.5" fill="currentColor" r="1" />
                </g>
              </svg>
            </div>
            <div className="ml-3">
              <div className="text-white text-xs">{t("wallet")}</div>
              <div className="text-sm font-semibold text-white">
                {numberFormat(userdata.credit)} USDT
              </div>
            </div>
          </div>
          <div className="flex space-x-4">
            <Link
              to="/login/deposit"
              className="px-4 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700"
            >
               {t("deposit")}
            </Link>
            <Link
              to="/login/withdraw"
              className="px-4 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700"
            >
             {t("withdraw")}
            </Link>
          </div>
        </div>
        <div className="w-full h-auto lg:min-h-screen flex flex-wrap">
          <div className="w-full lg:w-1/5 px-1 lg:p-2">
            <div className="hidden lg:block">
              <CoinList />
            </div>

            <button
              onClick={() => setIsModalCoin(!isModalCoin)}
              className="block lg:hidden w-full justify-center text-center py-2 rounded-md shadow-lg border-yellow-500 border-2 bg-yellow-600 hover:bg-yellow-600 text-white text-xs txtinfo text-shadow-1"
            >
              <div className="flex justify-center">
                <PaidIcon fontSize="small" className="my-auto mr-1 md:mr-5 text-shadow-1" style={{filter:"drop-shadow(0px 1px 1px black)"}} />
                <p className="txtinfo my-auto">{t("view_all_cryptocurrency")}</p>
              </div>
            </button>
          </div>
          <div className="w-full lg:w-4/5 lg:py-5">
            <TradingViewWidget symbol={selectedSymbol} symbolname={selectedSymbolname}/>
          </div>
        </div>
        {isModalCoin ? (
          <Modal
            title={""}
            modalRender={(modal) => {
              return React.cloneElement(modal, {
                style: { ...modal.props.style, ...{  padding: "10px 5px 0px 5px" } },
              });
            }}
            open={isModalCoin}
            onOk={() => setIsModalCoin(!isModalCoin)}
            onCancel={() => setIsModalCoin(!isModalCoin)}
            width={800}
            style={{ top: 0, padding: 0 }}
            footer={null}
          >
            <CoinList className="block lg:hidden mt-5" />
          </Modal>
        ) : (
          <></>
        )}
      </div>
    </TradeContext.Provider>
  );
}

export default Trade;
