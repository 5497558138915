import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const CountdownTimer = ({min}) => {
  const [timeLeft, setTimeLeft] = useState(min * 60); // 3 minutes in seconds

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

//   useEffect(() => {
//     if (timeLeft === 0) {
//       Swal.fire({
//         title: 'Countdown Finished!',
//         text: 'The timer has expired.',
//         icon: 'info',
//       });
//     }
//   }, [timeLeft]);

  return (
    <div className="bg-gray-900 py-1">
      <p id="demo" className="text-center text-red-600 text-2xl">
        {timeLeft > 0 ? formatTime(timeLeft) : 'EXPIRED'}
      </p>
    </div>
  );
};

export default CountdownTimer;