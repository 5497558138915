import React, { useState, useContext } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenNotification from "../../../Toast/OpenNotification";
import apiForm from "../../../config/apiForm";

import { useTranslation } from "react-i18next";

import { InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import ErrorMessage from "../../../Toast/ErrorMessage";
import { DashboardContext } from "../Dashboard";
import config from "../../../config/configapi";
import { useCookies } from "react-cookie";

function Verify() {
  const { t } = useTranslation();

  const { userdata, get_user } = useContext(DashboardContext);
  const URL_HOST = `${config.API_SERVER}`;

  const [imageFrontCard, setImageFrontCard] = useState({});
  const [imageBackCard, setImageBackCard] = useState({});

  const [imagePreviewFrontCard, setImagePreviewFrontCard] = useState(
    userdata.imagefrontcard ? `${URL_HOST}${userdata.imagefrontcard}` : null
  );
  const [imagePreviewBackCard, setImagePreviewBackCard] = useState(
    userdata.imagebackcard ? `${URL_HOST}${userdata.imagebackcard}` : null
  );

  const [imageFrontCardBackup, setImageFrontCardBackup] = useState({});
  const [imageBackCardBackup, setImageBackCardBackup] = useState({});

  const [checkimageFrontCard, setCheckimageFrontCard] = useState(false);
  const [checkimageBackCard, setCheckimageBackCard] = useState(false);

  const [Cardindex, setCardindex] = useState(userdata.cardtype || 0);

  const handleChageCard = (e) => {
    setCardindex(e);
  };

  const [msgErr1, setMsgErr1] = useState("");

  const handleUploadImageFrontCard = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({ message: `${t("imgnotmore20mb")}`, type: 3 });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageFrontCard(file);
      setImagePreviewFrontCard(reader.result);
      setCheckimageFrontCard(true);
    };
    reader.readAsDataURL(file);
  };
  const handleDelImageFrontCard = (e) => {
    setImageFrontCard({});
    setImagePreviewFrontCard(null);
    setCheckimageFrontCard(true);
  };
  const handleUploadImageBackCard = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({ message: `${t("imgnotmore20mb")}`, type: 3 });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBackCard(file);
      setImagePreviewBackCard(reader.result);
      setCheckimageBackCard(true);
    };
    reader.readAsDataURL(file);
  };
  const handleDelImageBackCard = (e) => {
    setImageBackCard({});
    setImagePreviewBackCard(null);
    setCheckimageBackCard(true);
  };
  const Update = async (e) => {
    e.preventDefault();
    if (!imagePreviewFrontCard || !imagePreviewBackCard) {
      return setMsgErr1(`${t("enter_complete_info")}`);
    }
    setMsgErr1("");

    const formData = new FormData();
    formData.append("imagefrontcard", imageFrontCard);
    formData.append("imagebackcard", imageBackCard);
    formData.append("cardtype", Cardindex);

    formData.append("peopleId", userdata.id);

    await apiForm
      .post(`api/people/sendverifyuser`, formData)
      .then((res) => {
        OpenNotification({
          message: `${t("data_sent_successfully")}`,
          type: 1,
        });
        get_user();
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
  };
  const UpdateBank = async () => {};
  return (
    <div className="px-2 py-2 lg:py-0 lg:px-0">
      <div className="w-full lg:w-3/6 bg-zinc-800 rounded-xl shadow-md mx-auto  px-5 py-2 text-white">
        <div className="flex ">
          <p className="mr-3">{t("status")} :</p>
          {userdata.verify_status === 0 ? (
            <>
              <DoNotDisturbOnIcon className="text-red-600 mr-2" />
              <p>{t("not_verify")}</p>
            </>
          ) : userdata.verify_status === 1 ? (
            <>
              <CheckCircleIcon className="text-green-600 mr-2" />
              <p>{t("verify_successfully")}</p>
            </>
          ) : userdata.verify_status === 2 ? (
            <>
              <CancelIcon className="text-red-600 mr-2" />
              <p>{t("fail_verify")}</p>
            </>
          ) : (
            <>
              <ChangeCircleIcon className="text-orange-400 mr-2" />
              <p>{t("waiting_verify")}</p>
            </>
          )}
        </div>
        <div className=" w-full md:w-full mx-auto my-5 py-2 px-2 bg-slate-700 rounded-md">
          <InputLabel className="my-auto text-white" style={{ color: "white" }}>
            {t("cardtype")}
          </InputLabel>
          <Select
            name="Cardindex"
            size="small"
            label={t("cardtype")}
            disabled={userdata.verify_status === 0 || userdata.verify_status === 2 ?false:true}
            defaultValue={Cardindex}
            value={Cardindex}
            onChange={(e) => handleChageCard(e.target.value)}
            fullWidth
            style={{ color: "white" }}
          >
            <MenuItem value={0}>{t("idcard")}</MenuItem>
            <MenuItem value={1}>{t("passport")}</MenuItem>
            <MenuItem value={2}>{t("ssncard")}</MenuItem>
            <MenuItem value={3}>{t("driverlicense")}</MenuItem>
          </Select>
        </div>

        <div className="w-full rounded-lg shadow-xl bg-gray-800 py-1 my-2">
          <div className="m-4">
            {userdata.verify_status === 0 || userdata.verify_status === 2 ? (
              <div>
                <label className="inline-block mb-2 text-gray-300">
                  {t("upload_front_card")}&nbsp;
                  {Cardindex === 0
                    ? `${t("idcard")}`
                    : Cardindex === 1
                    ? `${t("passport")}`
                    : Cardindex === 2
                    ? `${t("ssncard")}`
                    : `${t("driverlicense")}`}
                </label>
                <IconButton onClick={handleDelImageFrontCard}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </div>
            ) : (
              <></>
            )}

            <div className="flex items-center justify-center w-full">
              {imagePreviewFrontCard ? (
                <img
                  className="rounded-sm max-h-56 w-full object-contain"
                  src={imagePreviewFrontCard && imagePreviewFrontCard}
                  alt="trade"
                />
              ) : (
                <label className="flex flex-col w-full h-56 border-4 border-blue-200 border-dashed hover:bg-gray-700 cursor-pointer hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7 mt-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {t("select_image_file")}
                    </p>
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="opacity-0"
                    name="imagedriving"
                    onChange={handleUploadImageFrontCard}
                
                  />
                  
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg shadow-xl bg-gray-800 py-1 my-2">
          <div className="m-4">
            {userdata.verify_status === 0 || userdata.verify_status === 2 ? (
              <div>
                <label className="inline-block mb-2 text-gray-300">
                  {t("upload_back_card")}&nbsp;
                  {Cardindex === 0
                    ? `${t("idcard")}`
                    : Cardindex === 1
                    ? `${t("passport")}`
                    : Cardindex === 2
                    ? `${t("ssncard")}`
                    : `${t("driverlicense")}`}
                </label>
                <IconButton onClick={handleDelImageBackCard}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </div>
            ) : (
              <></>
            )}
            <div className="flex items-center justify-center w-full">
              {imagePreviewBackCard ? (
                <img
                  className="rounded-sm max-h-56 w-full object-contain"
                  src={imagePreviewBackCard && imagePreviewBackCard}
                  alt="trade"
                />
              ) : (
                <label className="flex flex-col w-full h-56 border-4 border-blue-200 border-dashed hover:bg-gray-700 cursor-pointer hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7 mt-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {t("select_image_file")}
                    </p>
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="opacity-0"
                    name="imagedriving"
                    
                    onChange={handleUploadImageBackCard}
                  />
                </label>
              )}
            </div>
          </div>
        </div>
        {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
        <div className="w-full text-center my-2">
          {userdata.verify_status === 0 || userdata.verify_status === 2 ? (
            <button
              onClick={Update}
              className="text-center  text-white font-semibold mx-auto  px-10 py-2 rounded-md bg-blue-700 hover:bg-blue-900 shadow-md"
            >
              {t("send_for_inspection")}
            </button>
          ) : userdata.verify_status === 1 ? (
            <></>
          ) : (
            // <button
            //   onClick={UpdateBank}
            //   className="text-center  text-white font-semibold mx-auto  px-10 py-2 rounded-md bg-blue-700 hover:bg-blue-900 shadow-md"
            // >
            //   Update Bank
            // </button>
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verify;
