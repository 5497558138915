import React, { useState, useEffect } from "react";
import numberFormat from "../../../Function/NumberFormat2float";
import API_URL from "../../../config/api";

import { Table, Spin, Tag } from "antd";

import dayjs from "dayjs";
import Countdown_UI from "./Countdown";
import OpenNotification from "../../../Toast/OpenNotification";
import { useWebSocket } from "../WebSocketContext";

function TradingList() {
  const [alltrade, setAlltrade] = useState([]);
  const [alltradeBackup, setAlltradeBackup] = useState([]);
  const [alltradeloading, setAlltradeLoading] = useState(false);
  const { websocket ,sendMessage} = useWebSocket();

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.type === "refreshUserTrading") {
          get_UserAllTradeAdmin();
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.onmessage = null;
      }
    };
  }, [websocket]);

  useEffect(() => {
    get_UserAllTradeAdmin();
  }, []);

  const get_UserAllTradeAdmin = async () => {
    setAlltrade([]);
    setAlltradeBackup([]);

    setAlltradeLoading(true);
    await API_URL.get(`api/tradelist/getuseralltradeadmin`)
      .then((res) => {
        const getalluser = res.data;

        if (getalluser.length !== 0) {
          setAlltrade(getalluser);
        }
      })
      .catch((err) => {
        OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
    setAlltradeLoading(false);
  };

  const adminSetTrade = async (data, adminstatus) => {
    await API_URL.put(`api/tradelist/adminsettrade`, {
      adminstatus: adminstatus,
      opening_price: data.opening_price,
      type_order: data.type_order,
      id: data.id,
    })
      .then((res) => {
        OpenNotification({ message: "เสร็จสิ้น", type: 1 });
        sendMessage({ type: "refreshUserTrading" });
        // get_UserAllTradeAdmin();
      })
      .catch((err) => {
        OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
  };

  const columns_trading = [
    {
      title: `ลำดับ`,
      align: "center",
      width: "8%",
      render: (alltrade, record, index) => (
        <>
          <p className=" text-xs text-black my-auto px-2">{index + 1}</p>
      
          <Countdown_UI
            date={
              // Date.now() + Number(dayjs(alltrade.closing_time).diff(dayjs())) 
              Date.now() + Number(dayjs(alltrade.closing_time).diff(dayjs())) + 3000
            }
            id={alltrade.id}
            get_UserAllTradeAdmin={get_UserAllTradeAdmin}
          /> 
        </>
      ),
    },
    {
      title: `UID`,
      align: "center",
      width: "8%",
      render: (alltrade) => (
        <p className=" text-xs text-black my-auto px-2">
          {alltrade.people.uid} - {alltrade.people.firstname}
        </p>
      ),
    },

    {
      title: `หุ้น`,
      align: "center",
      render: (alltrade) => (
        <p className=" text-xs text-black my-auto px-2">
          {alltrade.symbol} ( {numberFormat(alltrade.amount)} USDT )
        </p>
      ),
    },

    {
      title: `ปรับ`,
      align: "center",
      render: (alltrade) => (
        <p
          className={
            "wsn text-xs text-black my-auto px-0.5 py-0.5 border-2 rounded-md  " +
            (alltrade.adminstatus === 0
              ? "border-stone-400 bg-stone-50"
              : alltrade.adminstatus === 1
              ? "border-blue-400 bg-blue-50"
              : "border-red-400 bg-red-50")
          }
        >
          {alltrade.adminstatus === 0
            ? "ไม่ได้ set"
            : alltrade.adminstatus === 1
            ? "ชนะ"
            : "แพ้"}
        </p>
      ),
    },
    {
      title: `ปรับ`,
      align: "center",
      render: (alltrade) => (
        <div className="grid grid-cols-2 gap-1 whitespace-nowrap">
          <button
            onClick={() => adminSetTrade(alltrade, 1)}
            className=" justify-center text-center py-1 md:py-2 px-2 lg:px-2 rounded-md shadow-lg bg-blue-500 hover:bg-blue-700 text-white text-sm md:text-md"
          >
            ชนะ
          </button>
          <button
            onClick={() => adminSetTrade(alltrade, 2)}
            className=" justify-center text-center py-1 md:py-2 px-2 lg:px-2 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
          >
            แพ้
          </button>
        </div>
      ),
    },

    {
      title: `ซื้อ/ขาย`,
      align: "center",
      render: (alltrade) => (
        <>
          {alltrade.type_order === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ซื้อ
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ขาย
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `เวลาเปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.opening_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `เปิด-ราคา`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.opening_price)}
        </p>
      ),
    },
    {
      title: `เวลาปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.closing_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `ปิด-ราคา`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.closing_price)}
        </p>
      ),
    },
    // {
    //   title: `นับถอยหลัง`,
    //   align: "center",
    //   render: (alltrade) => (
    //     <Countdown_UI
    //       date={Date.now() + Number(dayjs(alltrade.closing_time).diff(dayjs()))}
    //       id={alltrade.id}
    //     />
    //   ),
    // },
  ];
  return (
    <div>
      <Table
        className="table-striped-rows  "
        size="middle"
        scroll={{
          x: 1200,
        }}
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_trading}
        dataSource={alltrade}
        loading={{
          indicator: (
            <div>
              <Spin size="large" />
            </div>
          ),
          spinning: alltradeloading,
        }}
      />
    </div>
  );
}

export default TradingList;
